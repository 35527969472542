.ordercard {
  margin: 10px;
  position: relative;
  background-color: #fbfbfb !important;
  .ordertime {
    position: absolute;
    top: 16px;
    right: 16px;
  }
  .orderno {
    font-weight: bold;
  }
  .client {
    padding-top: 10px;
  }
  .address {
    margin: 10px 0 0;
  }
  .note {
    padding-top: 20px;
    font-style: italic;
  }
  .detail-command {
    margin: 40px 20px 20px 20px;
  }
  &.print {
    box-shadow: none;
    margin: 0 50px;
    background-color: #fff !important;
  }
}
