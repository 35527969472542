body {
  background-color: #f5f5f5;
  color: #6e6e6e;
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: "Roboto", sans-serif;
  min-height: 100vh;
  display: flex;
  overflow-x: hidden;
  #root {
    flex: 1;
    display: flex;
    flex-direction: column;
    .height-handler {
      min-height: 110vh;
    }
    .admin-container {
      margin: 8px;
      // min-width: 1000px;
      max-width: 1500px;
      margin: 0 auto;
      width: 100%;
      .order-datepicker {
        margin-left: 200px;
      }
      .order-tabDatepicker {
        position: absolute;
        top: 8px;
        right: 8px;
        margin-top: 0;
        margin-bottom: 0;
        input {
          text-align: center;
        }
      }
      .custom-panel-inner {
        text-align: center;
        .info {
          margin-bottom: 10px;
        }
      }
      .toolbar {
        padding: 0 !important;
      }
      .time-container {
        .picked-time {
          margin: 60px 12px 0;
        }
        &:first-child {
          .picked-time {
            margin: 0 12px 0;
            padding-top: 20px;
          }
        }
      }
      hr {
        margin: 8px 10px;
      }
    }
    .side-inputs {
      display: flex;
      justify-content: space-between;
      > div {
        width: 45%;
      }
    }
    .notice {
      font-style: italic;
      font-size: 14px;
      text-align: center;
      width: 100%;
    }
    .MuiMenu-list {
      max-height: 200px;
    }
    .error-msg {
      width: 100%;
      text-align: center;
      margin-top: 20px;
      margin-bottom: -20px;
      color: #f44336;
    }
    footer {
      margin-top: auto;
      text-align: center;
      margin-bottom: 20px;
      align-self: flex-end;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 25px;
        margin-left: 5px;
      }
    }
  }
}
