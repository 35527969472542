.login-wrapper {
  width: 500px;
  height: 500px;
  margin: 100px auto;
  .login-content {
    display: flex;
    flex-direction: column;

    padding: 50px;
    & > * {
      margin: 20px 0;
    }
    img {
      margin: auto;
    }
  }
}
