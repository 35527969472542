.menu-container {
  background-color: #fff;
  min-width: 380px;
  width: 600px;
  margin: auto;
  padding: 20px;
  &.loading {
    display: flex;
    justify-content: center;
    min-height: 500px;
  }

  .closed {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 500px;
    text-align: center;
    font-size: 22px;
    padding: 40px;
    flex-wrap: wrap;
    .closed-msg {
      width: 100%;
      a {
        color: #6e6e6e;
      }
    }
    .time-container {
      width: 405px;
      .time-row {
        display: flex;
        margin-bottom: 5px;
        font-size: 18px;
        &.bold {
          font-weight: bold;
        }
      }
      .day {
        width: 225px;
        text-align: left;
      }
      .from {
        flex: 1;
        text-align: left;
      }
      .to {
        flex: 1;
        text-align: right;
      }
    }
    .note {
      font-size: 12px;
      font-style: italic;
    }
  }

  &.summary,
  &.information {
    padding: 0 16px 40px;
    hr {
      margin: 60px 0;
    }
  }
  .quantity-wrapper {
    min-width: 155px;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media print {
      display: none;
    }
    input {
      max-width: 80px;
    }
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
}

.button-container {
  margin: auto;
  min-width: 380px;
  width: 600px;
  display: flex;
  justify-content: space-around;
}
.menusection-wrapper {
  .quantity-wrapper {
    opacity: 0;
    transition: all 0.3s ease-in-out;
    &.edit {
      opacity: 1;
    }
  }
  &:hover {
    .quantity-wrapper {
      opacity: 1;
    }
  }
}
