.delivery-question {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 40px;
  margin-bottom: 10px;
  .custom-input-box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 75px;
    padding: 18.5px 14px;
    border-color: hsla(0, 0%, 0%, 0.23);
    cursor: pointer;
    small {
      display: block;
      text-align: center;
    }
    svg.image {
      height: 40px;
      width: auto;
      margin-right: 10px;
    }
    svg:not(.image) {
      display: none;
      position: absolute;
      top: 5px;
      right: 5px;
    }
    &.disabled {
      opacity: 0.75;
      &:hover {
        cursor: not-allowed;
      }
    }
    &:hover:not(.disabled) {
      border-color: rgb(37, 37, 37);
    }
    &.selected {
      svg:not(.image) {
        display: block;
      }
    }
  }
}
