.settings {
  width: 800px;
  max-width: 100%;
  margin: auto;
  .description {
    width: 250px;
    margin-right: 150px;
    flex: initial;
  }
  .switch-wrapper {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
}
