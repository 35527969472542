.dashboard-container {
  margin-top: 20px;
  .stat-wrapper {
    padding-top: 25px;
    width: 100%;
    .stat {
      overflow: visible;
      min-height: 100px;
      .header {
        position: relative;
        text-align: right;
        .box {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 75px;
          height: 75px;
          border-radius: 3px;
          color: #fff;
          position: absolute;
          top: -30%;
          &.green {
            background: linear-gradient(60deg, #66bb6a, #43a047);
            box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
              0 7px 10px -5px rgba(76, 175, 80, 0.4);
          }
          &.orange {
            background: linear-gradient(60deg, #ffa726, #fb8c00);
            box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
              0 7px 10px -5px rgba(255, 152, 0, 0.4);
          }
          &.blue {
            background: linear-gradient(60deg, #26c6da, #00acc1);
            box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
              0 7px 10px -5px rgba(0, 172, 193, 0.4);
          }
          &.red {
            background: linear-gradient(60deg, #ef5350, #e53935);
            box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
              0 7px 10px -5px rgba(244, 67, 54, 0.4);
          }
          &.grey {
            background: linear-gradient(60deg, #454851, #3f424a);
            box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
              0 7px 10px -5px rgba(0, 0, 0, 0.4);
          }
        }
        .title {
          color: #999;
        }
        .data {
          color: #3c4858;
          font-size: 25px;
        }
      }
      .footer {
        font-size: 12px;
        text-align: center;
        color: #999;
        font-style: italic;
      }
    }
  }
}
