.orderDate-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px 0;
  > p {
    font-weight: bold;
  }
  .orderDate {
    width: 150px;
  }
}
